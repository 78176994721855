import * as React from 'react'
import PropTypes from 'prop-types'

import { DateTime } from 'luxon'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Chip from '@material-ui/core/Chip'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

import CreditCardIcon from '@material-ui/icons/CreditCardRounded'
import BankAccountIcon from '@material-ui/icons/AccountBalanceRounded'
import CheckIcon from '@material-ui/icons/CheckRounded'
import AddIcon from '@material-ui/icons/AddRounded'
import { useLoader } from 'genjo-ui/core/LoaderProvider'
import { useSnackbar } from 'genjo-ui/core/SnackbarProvider'

import Spacer from 'genjo-ui/core/Spacer'
import ConfirmationDialog from 'genjo-ui/core/ConfirmationDialog'

import { createCurrencyFormatter } from 'src/utils/currency'
// import { EditPaymentMethod } from './EditPaymentMethod'
import { firebaseFunctions } from 'src/services/firebase'
import { AddPaymentMethod } from './AddPaymentMethod'
import { Group, Stack } from '@mantine/core'


function PaymentMethodListItem({ isDefault, canRemove, customer, paymentMethod, onRemove, onMakeDefault }) {

  return (
    <ListItem
      sx={{ bgcolor: 'grey.100', borderRadius: 1, my: 0.5 }}
    >
      <ListItemIcon>
        {paymentMethod.object === 'card'
          ? <CreditCardIcon />
          : paymentMethod.object === 'bank_account'
          ? <BankAccountIcon />
          : null
        }
      </ListItemIcon>

      {paymentMethod.object === 'card' ? (
        <ListItemText
          primary={`•••• •••• •••• ${paymentMethod?.last4}`}
          secondary={paymentMethod?.brand}
        />
      ) : paymentMethod.object === 'bank_account' ? (
        <ListItemText
          primary={`${paymentMethod.accountType || ''} ••••${paymentMethod?.last4}`}
          secondary={paymentMethod?.bank}
        />
      ) : (
        <ListItemText
          primary="Unknown payment method type"
        />
      )}

      {isDefault ? (
        <Chip color="success" label="Default" icon={<CheckIcon />} sx={{ mr: 0.5 }} />
        ) : (
        <Chip
          label="Set as default"
          icon={<AddIcon />}
          sx={{ mr: 0.5 }}
          clickable
          onClick={() => onMakeDefault(paymentMethod.id)}
        />
      )}

      {canRemove && !isDefault && (
        <Chip
          label="Remove"
          onDelete={onRemove}
          onClick={onRemove}
          clickable
        />
      )}
    </ListItem>
  )
}



export function PaymentMethod({ account, stripeInfo, refreshStripeInfo }) {
  const loader = useLoader()
  const snackbar = useSnackbar()
  const { accountType, stripeCustomerId } = account

  const [paymentMethodToRemove, setPaymentMethodToRemove] = React.useState(null)

  const [isAdding, setIsAdding] = React.useState(false)
  const currencyFormatter = createCurrencyFormatter()

  async function handleManageAccount() {
    const createCustomerPortalSession = firebaseFunctions.httpsCallable('createCustomerPortalSession')

    const response = await createCustomerPortalSession({ accountId: account.id })

    if (response?.data?.status !== 'success') {
      return snackbar.error(response?.data?.message ?? 'Something went wrong.')
    }

    window.location.href = response?.data?.url
  }

  async function handleRemovePaymentMethod() {
    try {
      loader.open('Removing payment method.')

      const removePaymentMethod = firebaseFunctions.httpsCallable('removePaymentMethod')

      const response = await removePaymentMethod({
        paymentMethodId: paymentMethodToRemove,
        accountType,
      })

      console.log({ removeResponse: response })

      if (response?.data?.status !== 'success') {
        throw new Error(response?.data?.message)
      }

      snackbar.success('Payment method removed.')
      refreshStripeInfo()
    } catch (err) {
      console.log({ err })
      snackbar.error(err?.message ?? 'Something went wrong.')
    } finally {
      loader.close()
    }
  }

  async function handleMakeDefault(paymentMethodId) {
    try {
      loader.open('Setting default payment method.')

      const makeDefaultPaymentMethod = firebaseFunctions.httpsCallable('makeDefaultPaymentMethod')

      const response = await makeDefaultPaymentMethod({
        paymentMethodId,
        customerId: stripeCustomerId,
        accountType,
      })

      if (response?.data?.status !== 'success') {
        throw new Error(response?.data?.message)
      }

      snackbar.success('New default payment method set.')
      refreshStripeInfo()
    } catch (err) {
      console.log({ err })
      snackbar.error(err?.message ?? 'Something went wrong.')
    } finally {
      loader.close()
    }
  }

  const paymentMethods = stripeInfo?.paymentMethods ?? []
  const customer = stripeInfo?.customer ?? null

  return (
    <>
      <Card>
        <CardContent>
          <Typography variant="subtitle2" color="textSecondary">Next Payment</Typography>
          {Boolean(stripeInfo?.upcomingInvoice) && (
            <Typography variant="body1">
              <strong>
                {`on ${
                  DateTime.fromMillis(stripeInfo.upcomingInvoice.next_payment_attempt * 1000).toLocaleString(DateTime.DATE_FULL)
                } for ${
                  currencyFormatter.format(stripeInfo.upcomingInvoice.amount_due / 100)
                }`}
              </strong>
            </Typography>
          )}

          <Spacer axis="vertical" size={16} />

          <Typography variant="subtitle2" color="textSecondary">
            Payment Methods
          </Typography>
          <List dense={false}>
            {paymentMethods.map(paymentMethod => (
              <PaymentMethodListItem
                key={paymentMethod.id}
                paymentMethod={paymentMethod}
                customer={customer}
                onRemove={() => setPaymentMethodToRemove(paymentMethod.id)}
                onMakeDefault={handleMakeDefault}
                canRemove={paymentMethods.length > 1}
                isDefault={stripeInfo?.defaultPaymentMethod === paymentMethod.id}
              />
            ))}

            {paymentMethods.length === 0 && (
              <ListItem>
                <ListItemIcon><CreditCardIcon /></ListItemIcon>
                <ListItemText
                  primary="Please add a payment method!"
                />
              </ListItem>
            )}


          </List>

          {/* <Button onClick={() => setIsAdding(true)}>+ Add</Button> */}
          <Stack>
            <div>
              <Button onClick={handleManageAccount}>
                Manage payment methods
              </Button>
            </div>

            <div>
              <Button onClick={handleManageAccount}>
                Cancel subscription
              </Button>
            </div>

          </Stack>
        </CardContent>
      </Card>

      <ConfirmationDialog
        isOpen={Boolean(paymentMethodToRemove)}
        onClose={() => setPaymentMethodToRemove(false)}
        onConfirm={handleRemovePaymentMethod}
        title="Remove payment method?"
        message="This payment method will be permanently removed and no furthur charges will be made to it. Make sure to add a new payment method to prevent any disruptions to your account."
      />

      {isAdding && Boolean(account) && (
        <AddPaymentMethod
          account={account}
          onClose={() => setIsAdding(false)}
          isFirst={paymentMethods.length === 0}
          onSuccess={() => {
            setIsAdding(false)
            refreshStripeInfo()
          }}
        />
      )}
    </>
  )
}

PaymentMethod.propTypes = {
  account: PropTypes.object,
  stripeInfo: PropTypes.object,
}
