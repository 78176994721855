import * as React from 'react'

import { DateTime } from 'luxon'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Chip from '@material-ui/core/Chip'
import List from '@material-ui/core/List'
import Button from '@material-ui/core/Button'
import Stack from '@material-ui/core/Stack'
import ListItemButton from '@material-ui/core/ListItemButton'

import { UserModel } from 'src/data-model'
import { useAccount } from 'src/components/AccountProvider'
import { useStripeInfo } from './useStripeInfo'

import useModelList from 'src/components/useModelList'

import { createCurrencyFormatter } from 'src/utils/currency'
import Spacer from 'genjo-ui/core/Spacer'

import { SubscriptionPlan } from './SubscriptionPlan'
import { Details } from './Details'
import { PaymentMethod } from './PaymentMethod'
import { InvoicePoNumber } from './InvoicePoNumber'
import { ActivationCode } from './ActivationCode'


export function AccountOverview() {
  const { account, userLicenseCounts } = useAccount()
  const { stripeInfo, refreshStripeInfo } = useStripeInfo({ account })

  const { results: users = [] } = useModelList({
    model: UserModel,
    filter: query => query.where('accountId', '==', account?.id),
    filterKey: account?.id,
    skip: !account,
  })

  const currencyFormatter = createCurrencyFormatter()

  if (!stripeInfo) {
    return 'Loading...'
  }

  if (!stripeInfo.customer) {
    return 'This account has not been setup.'
  }

  console.log('account', account, stripeInfo)

  return (
    <>
      <Details account={account} refreshStripeInfo={refreshStripeInfo} />

      <Spacer axis="vertical" size={32} />

      <Grid container spacing={2} alignItems="stretch">
        <Grid item xs={12}>
          <Grid container spacing={1} alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography variant="h6">
                Subscription Plan
              </Typography>
            </Grid>

            <Grid item>
              <Button variant="white" onClick={refreshStripeInfo}>
                Refresh Billing Info
              </Button>
            </Grid>
          </Grid>
        </Grid>

        {account.isOldSchool && (
          <Grid item xs={12}>
            <ActivationCode account={account} />
          </Grid>
        )}

        <Grid item xs={7}>
          <SubscriptionPlan
            account={account}
            stripeInfo={stripeInfo}
            users={users}
            userLicenseCounts={userLicenseCounts}
            refreshStripeInfo={refreshStripeInfo}
          />
        </Grid>

        <Grid item xs={5}>
          <PaymentMethod
            stripeInfo={stripeInfo}
            account={account}
            refreshStripeInfo={refreshStripeInfo}
          />
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Typography variant="subtitle2" color="textSecondary">
                  Invoices
                </Typography>

                <InvoicePoNumber accountId={account.id} invoicePoNumber={account?.invoicePoNumber} />
              </Stack>

              <List>
                {stripeInfo?.invoices?.data.map((invoice, index) => (
                  <ListItemButton
                    key={invoice.id}
                    component="a"
                    href={invoice.invoice_pdf}
                    download
                    style={{
                      borderRadius: 16,
                      padding: 16,
                      backgroundColor: index % 2 ? 'unset' : '#eee'
                    }}
                  >
                    <Grid container spacing={2} alignItems="center">
                      <Grid item>
                        <Typography variant="subtitle2" color="textSecondary">
                          {DateTime.fromMillis(invoice.created * 1000).toLocaleString(DateTime.DATE_FULL)}
                        </Typography>
                      </Grid>
                      <Grid item style={{ flex: 1 }}>
                        <Typography variant="body1">
                        {currencyFormatter.format(invoice.subtotal / 100)}
                        </Typography>
                      </Grid>

                      <Grid item>
                        {invoice?.lines?.data?.map(line => (
                          <Typography key={line.id}>
                            {line.description ?? 'No description'}
                          </Typography>
                        ))}
                      </Grid>

                      <Grid item>
                        <Chip
                          label={invoice.status}
                          color={invoice.status === 'paid'
                            ? 'success'
                            : invoice.status === 'open'
                            ? 'error'
                            : 'warning'
                          }
                        />
                      </Grid>
                    </Grid>

                  </ListItemButton>
                ))}
              </List>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  )
}
